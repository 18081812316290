import type { CcpEntitlementQuery } from './__types__/CcpEntitlementQuery';

export const getCcpEntitlementFromQuery = (
	data: CcpEntitlementQuery | undefined,
): string | undefined => {
	if (!data) {
		return undefined;
	}

	return data.confluence_tenantContext?.licenseStates?.confluence?.ccpEntitlementId || undefined;
};
